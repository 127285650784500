import {Injectable} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private toastr: ToastrService) {
    }
    showErrors(error: any) {
        if (typeof error === 'string') {
            this.popToaster('error', 'Error', error);
        } else if (error) {
            error.forEach(e => {
                this.popToaster('error', e.keyName ? e.keyName : e.title, e.description);
            });
        }
    }

    // getConfig(): SnotifyToastConfig {
    //     this.snotifyService.setDefaults({
    //         global: {
    //             newOnTop: true,
    //             maxAtPosition: 6,
    //             maxOnScreen: 3,
    //         }
    //     });
    //     return {
    //         bodyMaxLength: 30,
    //         titleMaxLength: 15,
    //         backdrop: -1,
    //         position: SnotifyPosition.centerBottom,
    //         timeout: 5000,
    //         showProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true
    //     };
    // }

    popToaster(type, title = 'Error', message) {
        switch (type) {
            case 'info':
                this.toastr.info(message, title);
                break;
            case 'error':
                this.toastr.error(message, title);
                break;
            case 'success':
                this.toastr.success(message, title);
                break;
        }
    }


    showSuccess(param: { title: string; message: string }) {
        this.popToaster('success', param.title, param.message);
    }
}
