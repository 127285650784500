import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit{
    forgetPasswordForm: FormGroup;
    submitted = false;
    constructor(private router: Router,
                private fb: FormBuilder,
                private authService: AuthService) {
    }
    ngOnInit() {
        this.refresh();
    }

    refresh()
    {
        this.forgetPasswordForm = this.fb.group({
            contact: ['', Validators.required],
        });
    }
    submit()
    {
        this.submitted = true;
        if (!this.forgetPasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            this.authService.forgotPassword(this.forgetPasswordForm.getRawValue()).subscribe(data => {
                console.log(data);
                this.router.navigateByUrl('/otp-verification', {state: {contact: this.forgetPasswordForm.value.contact, requestType: 'forgot-password', forgotPasswordOtp: data.Otp}});
            }, error => {
                this.submitted = false;
            });
        }
    }
}
