<app-navbar></app-navbar>

<!-- Start Error Area -->
<section class="error-area">
    <div class="container">
        <div class="error-content">
            <img src="assets/images/404/404.png" alt="error">
            <h3>Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a href="/" class="default-btn">Go to Home <span></span></a>
        </div>
    </div>
</section>
<!-- End Error Area -->
