
<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blogs</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blogs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<section class="blog-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Latest Blogs</span>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-details"><img src="assets/images/blogs/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blogs/blog-details">Tax Efficiency Guide: How to Optimize Savings and Minimize Tax Burden</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blogs/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-details"><img src="assets/images/blogs/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blogs/blog-details">Maximize Your Savings: Effective Tax Strategies to Keep More Money in Your Pocket</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blogs/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-details"><img src="assets/images/blogs/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blogs/blog-details">Unlocking Tax Breaks: Ways to Save and Reduce Your Tax Liabilitys</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blogs/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-details"><img src="assets/images/blogs/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blogs/blog-details">Tax Efficiency Guide: How to Optimize Savings and Minimize Tax Burden</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blogs/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-details"><img src="assets/images/blogs/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blogs/blog-details">Maximize Your Savings: Effective Tax Strategies to Keep More Money in Your Pocket</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blogs/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-details"><img src="assets/images/blogs/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blogs/blog-details">3 Unlocking Tax Breaks: Ways to Save and Reduce Your Tax Liabilitys</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blogs/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pagination -->
        <div class="col-lg-12 col-sm-12">
            <div class="pagination-area">
                <a routerLink="/blogs" class="prev page-numbers">
                    <i class="fas fa-angle-double-left"></i>
                </a>
                <a routerLink="/blogs" class="page-numbers">1</a>
                <span class="page-numbers current" aria-current="page">2</span>
                <a routerLink="/blogs" class="page-numbers">3</a>
                <a routerLink="/blogs" class="page-numbers">4</a>
                <a routerLink="/blogs" class="next page-numbers">
                    <i class="fas fa-angle-double-right"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
</section>
<!-- End FAQ Area -->
