<div class="container-fluid login-page ptb-100">
    <div class="row justify-content-center" style="padding: 50px 0 100px 0">
        <h3 class="text-center d-flex justify-content-center ">Welcome to Acri<p class="rotateText">c</p>ent !</h3>
        <div class="col-lg-6 col-md-10 col-sm-12 align-self-center left-signup-content">
            <div class="bg-img1"><img src="./assets/images/signup/Back1.png" alt=""></div>
            <div class="bg-img2"><img src="./assets/images/signup/Back2.png" alt=""></div>
            <div class="row justify-content-center">
                <div class="col-lg-10 col-12">
                    <div class="card mt-lg-5 mt-3">
                        <div class="card-body">
                            <form novalidate  [formGroup]="userForm">
                                <div class="row text-center my-lg-3 my-2">
                                    <h3>Sign Up</h3>
                                    <p class="description">Enter details to create your account !</p>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 my-lg-2 my-1">
                                        <button class="btn login-btn w-100"><img src="./assets/images/signup/google.png" class="btn-icon" alt="">Sign
                                            Up with Google
                                        </button>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 my-lg-2 my-1">
                                        <button class="btn login-btn w-100"><img src="./assets/images/signup/phone2.gif" class="btn-icon" alt="">Sign
                                            Up with Phone number
                                        </button>
                                    </div>
                                </div>
                                <div class="row my-3 justify-content-center">
                                    <div class="col-5 bordered align-self-center">

                                    </div>
                                    <div class="col-2 text-center">
                                        or
                                    </div>
                                    <div class="col-5 bordered align-self-center">

                                    </div>
                                </div>
                                <div class="row mt-lg-4 mt-2">
                                   <div>
                                       <div class="col-12 custom-input py-2">
                                           <input type="text" placeholder="Username" formControlName="name" class="form-control">
                                           <img src="./assets/images/signup/user.png" class="input-icon" alt="">
                                       </div>
                                       <div *ngIf="userForm.controls.name.touched && userForm.controls.name.errors?.required"
                                            class="text text-danger mt-1">Username is required
                                       </div>
                                   </div>
                                    <div>
                                        <div class="col-12 custom-input py-2">
                                            <input type="email" placeholder="Email" formControlName="email" class="form-control">
                                            <img src="./assets/images/signup/email.png" class="input-icon" alt="">
                                        </div>
                                        <div *ngIf="userForm.controls.email.touched && userForm.controls.email.errors?.required"
                                             class="text text-danger mt-1">Email is required
                                        </div>
                                    </div>
                                   <div>
                                       <div class="col-12 custom-input py-2">
                                           <input type="number" placeholder="Contact" formControlName="contact" class="form-control">
                                           <img src="./assets/images/signup/phone2.gif" class="input-icon" alt="">
                                       </div>
                                       <div *ngIf="userForm.controls.contact.touched && userForm.controls.contact.errors?.required"
                                            class="text text-danger mt-1">Contact is required
                                       </div>
                                   </div>
                                    <div>
                                        <div class="col-12 custom-input py-2">
                                            <input type="password" placeholder="Password" class="form-control" formControlName="password">
                                            <img src="./assets/images/signup/hide.png" (click)="hideShow()" *ngIf="passwordStatus"
                                                 class="input-icon-password" alt="">
                                            <img src="./assets/images/signup/show-.png" (click)="hideShow()" *ngIf="!passwordStatus"
                                                 class="input-icon-password" alt="">
                                        </div>
                                        <div *ngIf="userForm.controls.password.touched && userForm.controls.password.errors?.required"
                                             class="text text-danger mt-1">Password is required
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 text-center my-3">
                                        <input type="checkbox" (click)="termsAccept()" class=" mx-1" style="cursor: pointer">
                                        <span class="forgot-password">By submitting this form, you accept and agree to our<a href="">Term Of Use</a></span>
                                    </div>
                                </div>
                                <div class="row mt-4 justify-content-center text-center">
                                    <div class=" col-lg-8 col-md-10 col-sm-12">
                                        <button type="button" class="btn custom-btn w-100"  *ngIf="termsCondition"(click)="submit()">Agree & Continue</button>
                                        <button type="button" class="btn custom-btn w-100" *ngIf="!termsCondition" disabled>Agree & Continue</button>
                                    </div>
                                    <span class="pt-3 forgot-password">Already have an account? <a routerLink="/login">Sign in </a></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-10 col-sm-12 right-signup-content">
            <div class="border-left"></div>
            <div class="row justify-content-center">
                <img src="./assets/images/signup/bg.gif" class="background-img " alt="">
                <div class="col-lg-8  col-11  ">
                    <h4 class="text-start">Maximum tax savings on ITR</h4>
                    <div class=" my-2 d-flex mt-3">
                        <img src="./assets/images/signup/edit.gif" width="60px" style="margin-right: 1rem" alt="">
                        <span class="alertInfo w-100">100% pre-fill - No manual data entries.</span>
                    </div>
                    <div class=" my-2 d-flex">
                        <img src="./assets/images/signup/idea.gif" width="60px" style="margin-right: 1rem" alt="">
                        <span class="alertDanger w-100">Tips and suggestions on tax savings.</span>
                    </div>
                    <div class=" my-2 d-flex">
                        <img src="./assets/images/signup/speedometer.gif" width="60px" style="margin-right: 1rem"
                             alt="">
                        <span class="alertWarning w-100">1000s of capital gains transactions imported in seconds.</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
