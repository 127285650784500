import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {StorageService} from "../../../shared/services/storage.service";
import {GlobalService} from "../../../shared/services/global.service";
import {AuthService} from "../../../shared/services/auth.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    navbarClass: any;
    user: any;

    constructor(
        private router: Router,
        location: Location,
        private globalService: GlobalService,
        private authService: AuthService
    ) {
        this.user =  StorageService.getItem('self');
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                    this.navbarClass = 'navbar-area';
                }
            });
    }

    ngOnInit() {
    }
    logout()
    {
        StorageService.clearAll();
        this.router.navigate(['/']);
        window.location.reload();
    }
}
