import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';


const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./components/pages/home/home-routing.module').then((m) => m.HomeRoutingModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./components/pages/faq/faq-routing.module').then((m) => m.FaqRoutingModule)
    },
    {
        path: 'support',
        loadChildren: () => import('./components/pages/contact/contact-routing.module').then((m) => m.ContactRoutingModule)
    },
    {
        path: 'about-us',
        loadChildren: () => import('./components/pages/about-us/about-us-routing.module').then((m) => m.AboutUsRoutingModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./components/layouts/login/login-routing.module').then((m) => m.LoginRoutingModule)
    },
    {
        path: 'otp-verification',
        loadChildren: () => import('./components/layouts/otp-verification/otp-verification.module').then((m) => m.OtpVerificationModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./components/layouts/register/register-routing.module').then((m) => m.RegisterRoutingModule)
    },
    {
        path: 'blogs',
        loadChildren: () => import('./components/pages/blogs/blogs-routing.module').then((m) => m.BlogsRoutingModule)
    },
    {
        path: 'services',
        loadChildren: () => import('./components/pages/services/services-routing.module').then((m) => m.ServicesRoutingModule)
    },
/* filling routes started here */
   /* {
        path: 'account',
        loadChildren: () => import('./components/pages/filling/v0/account/account-routing.module').then((m) => m.AccountRoutingModule)
    },*/
    { path: 'filling/2023/0/account', loadChildren: () => import('./components/pages/tax-filling/v0/account/account.module').then(m => m.AccountModule) },
    { path: 'assist-filling', loadChildren: () => import('./components/pages/assist-filling/assist-filling.module').then(m => m.AssistFillingModule) },
/* filling routes ended here */
    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
