<div class="container-fluid login-page ptb-100">
    <img src="./assets/images/login/Background2.png" class="background-gredient " alt="">
    <div class="row mt-5 justify-content-center">

        <!--    <div class="background-img-1"></div>-->
        <!--    <div class="background-img-2"></div>-->
        <h3 class="text-center d-flex justify-content-center title" >Welcome <p class="rotateText">B</p>ack to Acricent !</h3>

        <div class="col-xl-5 col-lg-7 col-md-10 col-sm-12 align-self-center left-content">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-12  ">
                    <div class="card mt-lg-5 mt-3">
                        <div class="card-body">
                            <form novalidate [formGroup]="loginForm">
                                <div class="row text-center my-lg-3 my-2">
                                    <h3>Log in</h3>
                                    <p class="description">Enter Details to Get Started! </p>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 my-lg-2 my-1">
                                        <button class="btn login-btn w-100"><img src="./assets/images/login/google.png" class="btn-icon" alt="">Log In with Google</button>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 my-lg-2 my-1">
                                        <button class="btn login-btn w-100"><img src="./assets/images/login/phone2.gif" class="btn-icon" alt="">Log In with Phone number</button>
                                    </div>
                                </div>
                                <div class="row my-3 justify-content-center">
                                    <div class="col-5 bordered align-self-center">

                                    </div>
                                    <div class="col-2 text-center">
                                        or
                                    </div>
                                    <div class="col-5 bordered align-self-center">

                                    </div>
                                </div>
                                <div class="row mt-lg-4 mt-2">
                                    <div class="col-12 custom-input py-2">
                                            <input type="text" placeholder="Enter your username" class="form-control" formControlName="username">
                                            <img src="./assets/images/login/email.png" class="input-icon" alt="">
                                    </div>
                                    <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required"
                                         class="text text-danger mt-1">Username is required
                                    </div>
                                    <div class="col-12 custom-input py-2">
                                        <input type="password" placeholder="Enter your password" class="form-control" formControlName="password">
                                        <img src="./assets/images/login/show-.png"  (click)="hideShow()"  class="input-icon-password" alt="">
                                    </div>
                                    <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                                         class="text text-danger mt-1">Password is required
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-end">
                                        <span class="forgot-password"><a routerLink="/login/forgot-password">Forgot password ?</a></span>
                                    </div>
                                </div>
                                <div class="row mt-4 justify-content-center text-center">
                                    <div class=" col-lg-4 col-md-6 col-sm-12">
                                        <button class="btn custom-btn  w-100" (click)="submit()">Login</button>
                                    </div>
                                    <span class="pt-3 forgot-password">Don't have an account ? <a routerLink="/register">Sign Up</a></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-7 col-lg-5 col-md-10 col-sm-12 right-content">
            <div class="border-left"></div>
            <div class="row justify-content-center">
                <img src="./assets/images/login/Background2.gif" class="background-img " alt="">
                <h4 class="text-center">Enter Details  |  Upload Documents  |  Get Refund</h4>
                <div class="col-lg-8 mt-3 col-11 text-center">
                    <p><img src="./assets/Auth/checked.gif" width="40px"  alt="">100% pre-fill - No manual data entries.</p>
                    <p><img src="./assets/Auth/checked.gif" width="40px"  alt="">Tips and suggestions on tax savings.</p>
                    <p><img src="./assets/Auth/checked.gif" width="40px"  alt="">1000s of capital gains transactions imported in seconds.</p>
                </div>
            </div>
        </div>
    </div>
</div>
