import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgxScrollTopModule} from 'ngx-scrolltop';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/layouts/preloader/preloader.component';
import {NavbarComponent} from './components/layouts/navbar/navbar.component';
import {SidebarComponent} from './components/layouts/sidebar/sidebar.component';
import {FooterComponent} from './components/layouts/footer/footer.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {HomeComponent} from './components/pages/home/home.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {AboutUsComponent} from './components/pages/about-us/about-us.component';
import {BlogsComponent} from './components/pages/blogs/blogs.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {ServicesComponent} from './components/pages/services/services.component';
import {LoginComponent} from './components/layouts/login/login.component';
import {RegisterComponent} from './components/layouts/register/register.component';
import {ForgotPasswordComponent} from './components/layouts/login/forgot-password/forgot-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AlertService} from './shared/services/alert.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {GlobalService} from './shared/services/global.service';
import {BaseService} from './shared/services/base.service';
import {AuthService} from './shared/services/auth.service';
import {UtilsService} from './shared/services/utils.service';
import {StorageService} from './shared/services/storage.service';
import {OtpVerificationComponent} from './components/layouts/otp-verification/otp-verification.component';
import {RouterModule} from '@angular/router';

import {CommonModule, JsonPipe} from '@angular/common';
import {NgbAlertModule, NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {HtpInterceptor} from "./shared/services/interceptor.service";


@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        NotFoundComponent,
        HomeComponent,
        FaqComponent,
        AboutUsComponent,
        BlogsComponent,
        ContactComponent,
        ServicesComponent,
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        OtpVerificationComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        AppRoutingModule,
        RouterModule,
        HttpClientModule,
        NgxScrollTopModule,
        NgbDatepickerModule,
        NgbAlertModule,
        FormsModule,
        JsonPipe,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            progressBar: true,
            progressAnimation: 'increasing',
            closeButton: true
        }),
    ],
    providers: [
        AlertService,
        GlobalService,
        BaseService,
        AuthService,
        UtilsService,
        StorageService,
        HtpInterceptor
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
