import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth.service";
import {Router} from "@angular/router";
import {GlobalService} from "../../../shared/services/global.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
    passwordStatus: any;
    loginForm: FormGroup;
    submitted = false;
    constructor(private fb: FormBuilder,
                private authService: AuthService,
                private router: Router,
                private globalServices: GlobalService) {
    }
    hideShow()
    {
        this.passwordStatus = !this.passwordStatus;
    }

    ngOnInit() {
        this.refresh();
    }

    refresh()
    {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    submit(){
            this.submitted = true;

            if (!this.loginForm.valid)
            {
                return;
            }
            if (this.submitted) {
            this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
                if (data.id) {
                    this.globalServices.setAccessToken(data.token);
                    this.globalServices.setSelf({
                        name: data.name,
                        email: data.email,
                        contact: data.contact,
                        user_id: data.id,
                        username: data.username,
                        user_type: data.user_type
                    });
                    this.router.navigate(['/'])
                        .then(() => {
                            window.location.reload();
                        });
                }
            }, error => {
                this.submitted = false;
            });
        }
    }
}
