<div class="container-fluid forgot-password ptb-100">
    <div class="row justify-content-center" style="padding: 0 0 100px 0">
        <div class="col-xl-6 col-lg-8 col-md-10 col-sm-12">
            <div class="card" >
                <div class="card-body text-center">
                    <form novalidate [formGroup]="forgetPasswordForm">
                        <h3 class="text-center">Forgot Password</h3>
                        <p>Enter your email or Phone Number and we will <br> Send you a reset link</p>
                        <img src="./assets/images/signup/forgot.gif" width="40%" alt="">
                        <br><span style="font-family: var(--poppinsFontFamily);color: grey">Enter your contact Number</span>
                        <div class="row justify-content-center">
                            <div class="col-lg-8 col-md-10 col-12 custom-input py-2">
                                <input type="text" placeholder="Contact number..." formControlName="contact" class="form-control">
                                <img src="./assets/images/signup/phone2.gif" class="input-icon" alt="">
                            </div>
                            <div *ngIf="forgetPasswordForm.controls.contact.touched && forgetPasswordForm.controls.contact.errors?.required"
                                 class="text text-danger mt-1">Contact is required
                            </div>
                        </div>
                        <div class="row justify-content-center mt-3">
                            <div class=" col-lg-4 col-md-6 col-sm-12">
                                <button class="btn custom-btn w-100" (click)="submit()">Send Me The OTP</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
