import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/services/auth.service';
import {GlobalService} from '../../../shared/services/global.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit{
    contact: any;
    requestType: any;
    forgotPasswordOtp: any;
    submitted: any;
    otp: string;
    otpVerificationForm: FormGroup;
    updatePasswordForm: FormGroup;

    constructor(private router: Router,
                private fb: FormBuilder,
                private authService: AuthService,
                private globalServices: GlobalService,
                private modalService: NgbModal) {
        console.log(this.router.getCurrentNavigation().extras.state.contact); // should log out 'bar'

        if (this.router.getCurrentNavigation().extras.state.requestType)
        {
            this.requestType = this.router.getCurrentNavigation().extras.state.requestType;
            this.forgotPasswordOtp = this.router.getCurrentNavigation().extras.state.forgotPasswordOtp;
            console.log(this.forgotPasswordOtp);
        }

        this.contact = this.router.getCurrentNavigation().extras.state.contact;
    }

    ngOnInit(): void {
        this.refresh();
    }
    refresh()
    {
            this.otpVerificationForm = this.fb.group(
                {
                    otp: ['', Validators.required],
                }
            );
            this.updatePasswordForm = this.fb.group(
                {
                    password: ['', Validators.required],
                }
            );
    }
    submit(params: any = {}, content ?: any)
    {
        params.otp =  this.otpVerificationForm.value.otp;
        params.contact =  this.contact;
        console.log(params);
        this.submitted = true;
        if (!this.otpVerificationForm.valid) {
            return;
        }
        if (this.submitted) {
            if (this.requestType)
            {
                const otp = this.otpVerificationForm.value.otp;

                if (otp === this.forgotPasswordOtp)
                {
                    this.modalService.open(content);
                }
                else{
                    return;
                }
            }
            else {
                this.authService.verifyUser(params).subscribe(data => {
                    // this.router.navigateByUrl('/otp-verification', {state: {contact: data.username}});
                    console.log(data);
                    data = data.data;
                    if (data.id) {
                        this.globalServices.setAccessToken(data.token);
                        this.globalServices.setSelf({
                            name: data.name,
                            email: data.email,
                            contact: data.contact,
                            user_id: data.id,
                            username: data.username,
                            user_type: data.user_type
                        });
                        this.router.navigateByUrl('/');
                        this.otpVerificationForm.reset();
                    }
                }, error => {
                    this.submitted = false;
                });
            }

        }
    }

    updatePassword(params: any = {})
    {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            console.log(this.updatePasswordForm.value);
            params.otp = this.forgotPasswordOtp;
            params.contact = this.contact;
            params.password = this.updatePasswordForm.value.password;
            this.authService.updateForgotPassword(params).subscribe(data => {
               this.modalService.dismissAll();
                this.router.navigateByUrl('/login');

            }, error => {
                this.submitted = false;
            });
        }
    }
}
