<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2  ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Support</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Support</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container page-title-bg3 ">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6 my-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <!--<i class="flaticon-email"></i>-->
                        <img src="./assets/images/contact-us/Mail%20us%20icon.png" alt="">
                    </div>
                    <h3>Mail</h3>
                    <p><a href="mailto:hello@karlson.com">Support@Acricent.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 my-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <!--<i class="flaticon-phone-call"></i>-->
                        <img src="./assets/images/contact-us/Chat%20Support.png" alt="">
                    </div>
                    <h3>Chat Support</h3>
                    <div class="btn btn-primary chat-with-us-btn">Chat with us</div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 my-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <!-- <i class="flaticon-marker"></i>-->
                        <img src="./assets/images/contact-us/WhaTSAPP%20sUPPORT%20ICon.png" alt="">
                    </div>
                    <h3>Whatsup Support</h3>
                    <button class="btn btn-primary whatsapp-btn">Connect with us</button>
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid">
        <div class="row justify-content-center my-5">
            <div class="col-10">
                <div class="row">
                    <div class="row">
                        <div class="col-12">
                            <div class="card" style="border-radius: 10px;border: none;box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);">
                                <div class="card-body">
                                    <div class="mapouter" style="border-radius: 10px">
                                        <div class="gmap_canvas">
                                            <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
                                                    marginheight="0" marginwidth="0"
                                                    src="https://maps.google.com/maps?width=2048&amp;height=527&amp;hl=en&amp;q=volmint&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                            <a href="https://embed-googlemap.com">embed-googlemap.com</a></div>
                                        <style>.mapouter {
                                            position: relative;
                                            text-align: right;
                                            width: 100%;
                                            height: 527px;
                                        }

                                        .gmap_canvas {
                                            overflow: hidden;
                                            background: none !important;
                                            width: 100%;
                                            height: 527px;
                                        }

                                        .gmap_iframe {
                                            height: 527px !important;
                                        }</style>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2>Drop us Message for any Query</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-4">
                <div class="contact-image">
                    <img src="assets/images/faq/contact-us.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control"
                                           placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control"
                                           placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                           placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5"
                                              placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message <span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
