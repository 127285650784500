<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="color: #5b5b5b;font-family: var(--poppinsFontFamily)">Reset Password</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form novalidate [formGroup] = "updatePasswordForm" >
            <div class="row">
                <div class="col">
                    <label for="password" style="color: darkgray;font-family: var(--poppinsFontFamily)">New Password</label>
                    <input type="text" class="form-control" id="password" formControlName="password" placeholder="Enter your new password..">
                    <div style="font-weight:400" *ngIf="updatePasswordForm.controls.password.touched && updatePasswordForm.controls.password.errors?.required"
                         class="text text-danger mt-1">Password is required
                    </div>
                </div>
            </div>
            <hr>
           <div class="row">
               <div class="col text-end">
                   <button type="button" style="width: 180px;height: 40px" class="btn custom-btn btn-outline-success" (click)="updatePassword()">Save</button>
               </div>
           </div>
        </form>
    </div>
</ng-template>
<div class="container-fluid otp-verification ">
    <div class="row justify-content-center" style="padding: 70px 0 100px 0">
        <div class="col-xl-4 col-lg-5 col-md-10 col-sm-12" >
            <div class="card">
                <div class="card-body text-center">
                    <form novalidate [formGroup] = "otpVerificationForm">
                        <h3 class="text-center">OTP Verification</h3>
                        <p>You will receive One Time Password on your <br> registered E-mail & Phone Number</p>
                        <img src="./assets/images/signup/otp.gif" width="40%" alt="">
                        <div class="row justify-content-center">
                            <div class="col-8 py-4 text-start" style="font-family: var(--poppinsFontFamily);color: darkgray;font-weight: 700">
<!--                                <label for="otp" class="text-start">OTP</label>-->
                                <input type="number" id="otp"  class="form-control text-center" placeholder="Enter OTP.." formControlName="otp">
                                <div style="font-weight:400" *ngIf="otpVerificationForm.controls.otp.touched && otpVerificationForm.controls.otp.errors?.required"
                                     class="text text-danger mt-1">Otp is required
                                </div>
                            </div>
                            <p>Didn’t Received the OTP? <a src="" class="resend">Resend OTP</a> </p>
                        </div>

                        <div class="row justify-content-center mt-3">
                            <div class=" col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                <button class="btn custom-btn w-100" (click)="submit({},content)">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--    <div class="col-lg-6 col-md-10 col-sm-12 align-self-center text-center">-->
        <!--      <img src="./assets/Auth/security.gif" class="security" alt="">-->
        <!--    </div>-->
    </div>
</div>
