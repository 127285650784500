import {Component, OnInit} from '@angular/core';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit{
    userForm: FormGroup;
    passwordStatus = true;
    submitted: any = false;
    termsCondition = false;
    constructor(private fb: FormBuilder,
                private toastr: ToastrService,
                private authService: AuthService,
                private router: Router) {
    }
    ngOnInit(): void {
        this.refresh();
    }
    refresh(){
        // this.toastr.success('Hello world!', 'Toastr fun!');
        this.userForm = this.fb.group(
            {
                name: ['', Validators.required],
                email: ['', Validators.required],
                password: ['', Validators.required],
                contact: ['', Validators.required],
            }
        );
    }
    patchFormValue(data)
    {
        this.userForm.patchValue(
            {
                name : (data && data.name) ? data.name : '',
                email : (data && data.email) ? data.email : '',
                password : (data && data.password) ? data.password : '',
                contact : (data && data.contact) ? data.contact : '',
            }
        );
    }
    submit()
    {
        this.submitted = true;
        if (!this.userForm.valid) {
            return;
        }
        if (this.submitted) {
            this.authService.create(this.userForm.getRawValue()).subscribe(data => {
                this.router.navigateByUrl('/otp-verification', {state: {contact: data.username}});
            }, error => {
                this.submitted = false;
            });
        }
    }
    termsAccept()
    {
        this.termsCondition = !this.termsCondition;
    }

    hideShow()
    {
        console.log(this.passwordStatus);
        this.passwordStatus = !this.passwordStatus;
    }


}
