<!-- Start Main Banner Area -->
<div class="banner-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-12">
                        <div class="banner-content">
                            <span class="sub-title">Creating. Results.</span>
                            <h1>Get paid up to 2 days early with direct deposit 2</h1>
                            <p>India’s largest tax and financial services software platform</p>
                            <form>
                                <input type="email" class="input-newsletter" placeholder="Enter your email address"
                                       name="EMAIL">
                                <button type="submit">Get Started</button>
                            </form>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="banner-image">
                            <img src="assets/images/banner/rocket.png" class="wow animate__animated animate__fadeInUp"
                                 data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/rocket-shape.png" class="wow fadeIn" data-wow-delay="1s" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="cloud-shape"><img src="assets/img/cloud-shape.png" alt="image"></div>
    <div class="cloud-icon1"><img src="assets/img/cloud1.png" alt="image"></div>
    <div class="cloud-icon2"><img src="assets/img/cloud2.png" alt="image"></div>
    <div class="cloud-icon3"><img src="assets/img/cloud3.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Services Area -->
<section class="featured-services-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>Our Featured Services</h2>
            <p>Real innovations and a positive customer experience are the heart of successful communication. No fake
                products and services.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box h-100">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>Self e-Filing</h3>
                    <p class="text-start">With the self-filing option, you can fill in the details on your own withina
                        few minutes. We have made tax filing Hassle-free and user-friendly to file ITR byfollowing a few
                        steps.</p>
                    <ul class="features-list-2">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                              Minimum manual practice and Auto-read &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;system
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Accurate pre-fill data as per Form 16
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                               Maximum Returns and Savings
                            </span>
                        </li>
                    </ul>
                    <a routerLink="/service-details" class="default-btn">Start Filling <span></span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box h-100">
                    <div class="icon color-facd60">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3>Assisted e-Filing</h3>
                    <p>File your ITR hassle-free entirely by yourself in
                        under 3 minutes. We’ve made e-filing
                        incredibly easy for you.</p>
                    <ul class="features-list-2">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                              Minimum manual practice and Auto-read system
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Accurate pre-fill data as per Form 16
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                               Maximum Returns and Savings
                            </span>
                        </li>
                    </ul>
                    <a routerLink="/service-details" class="default-btn">Start Filling <span></span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box h-100">
                    <div class="icon color-1ac0c6">
                        <i class="flaticon-research"></i>
                    </div>
                    <h3>Tax Planing</h3>
                    <p>File your ITR hassle-free entirely by yourself in
                        under 3 minutes. We’ve made e-filing
                        incredibly easy for you.</p>
                    <ul class="features-list-2">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                              Minimum manual practice and Auto-read system
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Accurate pre-fill data as per Form 16
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                               Maximum Returns and Savings
                            </span>
                        </li>
                    </ul>
                    <a routerLink="/service-details" class="default-btn">Start Filling <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Pricing Plan</span>
            <h2>Choose Your Best Plan</h2>
            <p>Real innovations and a positive customer experience are the heart of successful communication. No fake
                products and services.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                    </div>
                    <div class="price">
                        <sub>$</sub>
                        39.99
                        <sub>/mo</sub>
                    </div>
                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i>Upload Form 16 and E-file.</li>
                        <li><i class="flaticon-tick"></i> Review Income-tax records before.</li>
                        <li><i class="flaticon-tick"></i> Complete access to your file.</li>
                        <li><i class="flaticon-tick"></i> Easy step-wise process.</li>
                        <li><i class="flaticon-tick"></i> 24/7 Customer Support</li>
                        <li><i class="flaticon-close"></i>Get 100% Refund.</li>
                        <li><i class="flaticon-close"></i> Full Year Assiatance.</li>
                    </ul>
                    <a routerLink="/" class="get-started-btn">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box red">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                    </div>
                    <div class="price">
                        <sub>$</sub>
                        49.99
                        <sub>/mo</sub>
                    </div>
                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i>Upload Form 16 and E-file.</li>
                        <li><i class="flaticon-tick"></i> Review Income-tax records before.</li>
                        <li><i class="flaticon-tick"></i> Complete access to your file.</li>
                        <li><i class="flaticon-tick"></i> Easy step-wise process.</li>
                        <li><i class="flaticon-tick"></i> 24/7 Customer Support</li>
                        <li><i class="flaticon-tick"></i>Get 100% Refund.</li>
                        <li><i class="flaticon-tick"></i> Full Year Assiatance.</li>
                    </ul>
                    <a routerLink="/" class="get-started-btn">Get Started</a>
                </div>
            </div>

        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Pricing Area -->

<!-- Start Step Area -->
<section class="services-area custom-service-area ptb-100  bg-F4F7FC">
    <div class="container">

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 align-self-center">
                <div class="section-title">
                    <span class="sub-title">What We Do</span>
                    <h2>3 Easy Step</h2>
                    <p class="text-start sub-title-2">File income tax easily in a 3 steps: gather your financial
                        documents, organize deductions, fill out the required forms accurately, and submit
                        electronically or by mail. Stay stress-free while fulfilling your tax obligations
                        efficiently.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <img src="./assets/images/income-tax/files.webp" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="custom-single-services-box">
                    <div class="icon">
                        <i class="flaticon-digital-marketing"></i>
                    </div>
                    <h3><a routerLink="/service-details">Pre-Fill Data</a></h3>
                    <p>Pre-fill your basic details, income, deductions, TDS from IT Department.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="custom-single-services-box">
                    <div class="icon bg-00aeff">
                        <i class="flaticon-research"></i>
                    </div>
                    <h3><a routerLink="/service-details">Save Tax</a></h3>
                    <p>With a whole section dedicated on saving tax, it’s never been this easy</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="custom-single-services-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3><a routerLink="/service-details">File ITR</a></h3>
                    <p>We file with govt. and you’ll get the acknowledgement from govt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Step  Area -->
<!-- Start Partner Area -->
<div class="partner-area ptb-100 pt-0">
    <div class="container">
        <h5 class="text-center custom-sub-title my-5">Trusted by your favorite brands</h5>
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner1.png" alt="image">
                    <img src="assets/images/partners/partner1.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner2.png" alt="image">
                    <img src="assets/images/partners/partner2.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner3.png" alt="image">
                    <img src="assets/images/partners/partner3.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner4.png" alt="image">
                    <img src="assets/images/partners/partner4.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner5.png" alt="image">
                    <img src="assets/images/partners/partner5.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner6.png" alt="image">
                    <img src="assets/images/partners/partner6.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner7.png" alt="image">
                    <img src="assets/images/partners/partner7.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner8.png" alt="image">
                    <img src="assets/images/partners/partner8.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner9.png" alt="image">
                    <img src="assets/images/partners/partner9.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/images/partners/partner10.png" alt="image">
                    <img src="assets/images/partners/partner10.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->
<!-- Start building trust area-->
<div class="security-area ptb-100 pt-0">
    <div class="container">
        <div class="row">
            <div class="section-title">
                <h2 class="text-center mt-5">Commited to Building Trust</h2>
                <p class="sub-title">
                    Ensuring utmost security in every step. Safeguarding your sensitive financial information and
                    protecting your privacy throughout the entire tax filing process.
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 my-2">
                <div class="card">
                    <div class="card-body text-center">
                        <img src="./assets/images/SecurityFeatures/security.gif" width="70%" alt="">
                        <h3>Security</h3>
                        <p>We use SSL certified 2048-bit SHA256 encryption for site wide data transmission keeping all
                            vulnerabilities away.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 my-2">
                <div class="card">
                    <div class="card-body text-center">
                        <img src="./assets/images/SecurityFeatures/accuracy.gif" width="70%" alt="">
                        <h3>Accuracy</h3>
                        <p>We use SSL certified 2048-bit SHA256 encryption for site wide data transmission keeping all
                            vulnerabilities away.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 my-2">
                <div class="card">
                    <div class="card-body text-center">
                        <img src="./assets/images/SecurityFeatures/confidential.gif" width="70%" alt="">
                        <h3>Confidentiality</h3>
                        <p>We use SSL certified 2048-bit SHA256 encryption for site wide data transmission keeping all
                            vulnerabilities away.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 my-2">
                <div class="card">
                    <div class="card-body text-center">
                        <img src="./assets/images/SecurityFeatures/privacy.gif" width="70%" style="padding: 2.2rem"
                             alt="">
                        <h3>Privacy</h3>
                        <p>We use SSL certified 2048-bit SHA256 encryption for site wide data transmission keeping all
                            vulnerabilities away.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End building trust area-->
<!-- Start Features Area -->
<section class="features-area ptb-100 pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Features</span>
            <h2>We always try to understand customers expectation</h2>
        </div>

        <div class="tab features-list-tab">
            <ul class="tabs justify-content-center">
                <li><a href="#" class="bg-fa7070">
                    <i class="flaticon-achievement"></i>
                    <span>Support</span>
                </a></li>
                <li><a href="#" class="bg-00aeff">
                    <i class="flaticon-architecture"></i>
                    <span>Quick</span>
                </a></li>
                <li><a href="#" class="bg-c679e3">
                    <i class="flaticon-digital-marketing"></i>
                    <span>Affordable</span>
                </a></li>
                <li><a href="#" class="bg-eb6b3d">
                    <i class="flaticon-analytics"></i>
                    <span>Savings</span>
                </a></li>

            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>User Experience</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/images/Gaining-Edge/features1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/images/Gaining-Edge/features2.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Product Design</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Digital Marketing</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/images/Gaining-Edge/features3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/images/Gaining-Edge/features4.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Branding</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
</section>
<!-- End Features Area -->


<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Project Completed</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Client Satisfaction</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100 pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Meet Our Awesome Team Member</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/images/teams/team1.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>John Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/images/teams/team2.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Lucy Eva</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/images/teams/team3.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Start Solution Area -->
<section class="solution-area ptb-100 extra-pb jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <span class="sub-title">Watch Our Video</span>
                    <h2>Get Better Solution For Save Taxes</h2>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.
                    </p>
                    <a routerLink="/contact" class="default-btn">Contact Us <span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
                        class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Solution Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Some Lovely Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/images/clients/client1.jpg" alt="image">
                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/images/clients/client2.jpg" alt="image">
                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/images/clients/client3.jpg" alt="image">
                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/images/clients/client4.jpg" alt="image">
                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/images/clients/client5.jpg" alt="image">
                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->


<!-- Start Subscribe Area -->
<section class="subscribe-area bg-F4F7FC">
    <div class="container-fluid p-0">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get only new update from this newsletter</h2>

                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required
                           autocomplete="off">
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
</section>
<!-- End Subscribe Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Latest Blogs</span>
            <h2>Our Latest Blogs And News For Our Clients</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/images/blogs/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Tax Efficiency Guide: How to Optimize Savings and Minimize Tax
                            Burden</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i
                            class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/images/blogs/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Maximize Your Savings: Effective Tax Strategies to Keep More
                            Money in Your Pocket</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i
                            class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/images/blogs/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Unlocking Tax Breaks: Ways to Save and Reduce Your Tax
                            Liabilitys</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i
                            class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
</section>
<!-- End Blog Area -->
