<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Mon to Fri : 10:00AM - 06:00PM</span>
                            <a href="tel:+1235421457852">+123 54214 578 52</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Do You Have a Question?</span>
                            <a href="mailto:hello@karlson.com">info@volmint.com</a>
                        </li>
                        <li>
                            <i class="flaticon-social-media"></i>
                            <span>Socials Network</span>
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Quick Links</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Plans</a></li>
                        <li><a routerLink="/">Faq's</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Contact Us</h3>
                    <img src="./assets/images/footer/geo-map.png" alt="">
                    <br>
                   <div class="m-2 mt-3 contact-us-area">
                       <h5 class=""><i class="fa fa-map-marker-alt"></i>Address: <a target="_blank" href="https://www.google.com/maps/place/Volmint+Technologies/@28.606687,77.0803474,19z/data=!4m14!1m7!3m6!1s0x390d1bf854143ecb:0x40321da1ddfa8234!2sVolmint+Technologies!8m2!3d28.6066858!4d77.0809911!16s%2Fg%2F11tc4n56__!3m5!1s0x390d1bf854143ecb:0x40321da1ddfa8234!8m2!3d28.6066858!4d77.0809911!16s%2Fg%2F11tc4n56__?entry=ttu" >
                           29/2, Vijay Enclave,
                           Dwarka-Palam Road, Delhi-110045
                       </a></h5>
                       <h5 class="text-white"><i class="fa flaticon-email"></i> Email: <a href="mailto: support@volmint.com">
                           support@volmint.com
                       </a></h5>
                       <h5 class="text-white"><i class="fa fa-phone"></i> Mobile: <a href="tel: +91 9310828501">
                           +91 9310828501
                       </a></h5>
                   </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Copyright @{{currentYear}} <a href="https://volmint.com/" target="_blank">Volmint</a>. All rights reserved</p>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->
