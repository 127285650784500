<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}}">
    <div class="karlson-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand ps-5" routerLink="/"><img src="assets/images/logo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" routerLink="/" href="javascript:void(0)" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                Home
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" routerLink="about-us/" href="javascript:void(0)"
                               role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" routerLink="services/" href="javascript:void(0)"
                               role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Services
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" routerLink="blogs/" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                Blog
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" routerLink="support/" href="javascript:void(0)"
                               role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Support
                            </a>
                        </li>
                        <!--<li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" routerLink="contact/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Contact
                            </a>
                        </li>-->
                        <li class="nav-item">
                            <a routerLink="/faq" routerLinkActive="active" class="nav-link">Faq's</a>
                        </li>
                        <!--<li class="nav-item" *ngIf="user">
                            <a routerLink="filling/2023/0/account" routerLinkActive="active" class="nav-link">Account</a>
                        </li>-->
                        <li class="nav-item" *ngIf="user">
                            <a routerLink="assist-filling" routerLinkActive="active" class="nav-link">Tax Filling</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav me-auto" *ngIf="!user">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" routerLink="/login" href="javascript:void(0)"
                               role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Login
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" routerLink="/register" href="javascript:void(0)"
                               role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Register
                            </a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ml-auto" *ngIf="user">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-end" href="javascript:void(0)" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="./assets/images/clients/client1.jpg" width="20%" alt=""
                                     style="border-radius: 50%;border: 3px solid blue">
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                       class="nav-link">Dashboard</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a (click)="logout()" routerLink="" routerLinkActive="active" class="nav-link">Logout</a>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
