import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get ADMIN_URL(): string {
        return environment.appUrl + 'admin/';
    }

    static get API_URL(): string {
        return environment.appUrl + 'api/';
    }
    static get CREATE(): string {
        return AppUrl.API_URL + 'register';
    }
    static get VERIFY_USER(): string {
        return AppUrl.API_URL + 'verify-contact';
    }
    static get LOGIN(): string {
        return AppUrl.API_URL + 'login';
    }

    static get SELF(): string {
        return AppUrl.API_URL + 'self';
    }
    static get FORGOT_PASSWORD(): string {
        return AppUrl.API_URL + 'forgot-password';
    }
    static get UPDATE_FORGOT_PASSWORD(): string {
        return AppUrl.API_URL + 'forgot-password-update';
    }

    static UPDATE_SELF(): string {
        return AppUrl.ADMIN_URL + 'update-self';
    }

    static UPDATE_PASSWORD(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'update-password/' + uniqueId;
        }
    }

    static UPDATE_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'head-counsellor-status/' + uniqueId;
        }
    }

    static LOGOUT(): string {
        return AppUrl.ADMIN_URL + 'logout';
    }

    static FILE_UPLOAD(): string {
        return AppUrl.API_URL + 'file-upload';
    }

    static STATES(): string {
        return AppUrl.ADMIN_URL + 'states';
    }

    static USER(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'user/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'user';
        }
    }

    static SELF_PROFILE_PASSWORD(): string {
        return AppUrl.ADMIN_URL + 'update-user-password';
    }
    static get USER_DOC(): string {
        return AppUrl.API_URL + 'user-doc';
    }
    static USER_DOC_EDIT_STATUS(id): string {
        return AppUrl.API_URL + 'edit-status/'+ id;
    }
}
